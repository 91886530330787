import React, { Suspense, useRef } from 'react'
import { Canvas } from '@react-three/fiber'
import { Environment } from '@react-three/drei'
import Master from './Master'
import Overlay from './Overlay'

export default function Main() {
  const overlay = useRef()
  const caption = useRef()
  const scroll = useRef(0)
  return (
    <>
      <Canvas
        shadows
        onCreated={(state) => state.events.connect(overlay.current)}
        >
        <ambientLight intensity={0.6} />
        <fog attach='fog' args={['#000', 15, 30]} />
        <Suspense fallback={null}>
          <Master scroll={scroll} />
          <Environment files='/venice_sunset_1k.hdr' intensity={1} />
        </Suspense>
      </Canvas>
      <Overlay ref={overlay} caption={caption} scroll={scroll} />
    </>
  )
}
