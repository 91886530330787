import React from 'react'
import styled from 'styled-components'
import Logo from './logo.svg'
const HeaderContainer = styled.div`
  position: absolute;
  display: flex;
  left: 0;
  right: 0;
  justify-content: space-between;
  z-index: 99;
`

const GetStarted = styled.a`
  margin: 20px;
  padding: 8px 14px;
  background: #121212;
  color: white;
  text-transform: uppercase;
  font-size: 0.8rem;
  cursor: pointer;
  text-decoration: none;
`

const LogoContainer = styled.div`
  width: 45px;
  margin: 15px;
  > img {
    filter: invert(1) brightness(1.5);
    width: 100%;
    height: auto;
  }
`

const Header = () => {
  return (
    <HeaderContainer>
      <LogoContainer>
        <img src={Logo} alt='Sisu Apps, Technology agency.'/>
      </LogoContainer>

      <GetStarted href="https://calendly.com/sisuapps/30min" target="_blank">
        Get Started
      </GetStarted>
    </HeaderContainer>
  )
}

export default Header
