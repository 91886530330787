/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, PerspectiveCamera, useAnimations } from '@react-three/drei'
import { useFrame } from "@react-three/fiber"
import { MathUtils } from 'three'

export default function Model({ scroll, ...props }) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/Master3.glb')
  const { actions } = useAnimations(animations, group)
  const extras = { receiveShadow: true, castShadow: true, roughness: 0, metallic: 0 }

  useEffect(() => void (actions["CameraAction.005"].play().paused = true), [])
  useFrame((state) => {
    actions["CameraAction.005"].time = MathUtils.lerp(actions["CameraAction.005"].time, actions["CameraAction.005"].getClip().duration * scroll.current, 0.05)
    const t = state.clock.getElapsedTime()
    const g = group.current.children[0]
    g.position.y = MathUtils.lerp(g.position.y,(30 + Math.sin(t)) / 8, 0.01)
  })

  return (
    <group ref={group} {...props} dispose={null}>
        <group
          name="OverallParent" position={[0.06, 4.04, 0.35]} scale={0.25}>
          <mesh name="Board" geometry={nodes.Board.geometry} material={materials.Primary} position={[-4.92, 24.43, -43.28]} rotation={[0.89, 0.33, -0.38]} scale={3.6} {...extras} />
          <mesh name="Hand" geometry={nodes.Hand.geometry} material={materials.Primary} position={[-0.25, -16.15, -1.38]} rotation={[-2.25, -0.82, -3.01]} scale={5.01}  {...extras}/>
          <mesh name="Ice_Cream_Cone" geometry={nodes.Ice_Cream_Cone.geometry} material={materials.Primary} position={[32.9, -8.57, 17.31]} rotation={[-0.59, 0, 0]} scale={94.01} {...extras} />
          <mesh name="L_helmet_01" geometry={nodes.L_helmet_01.geometry} material={materials.Primary} position={[-25.5, 48.94, 11.02]} scale={4.84} {...extras} />
          <mesh name="Notebook" geometry={nodes.Notebook.geometry} material={materials.Primary}  {...extras} />
          <mesh name="Rocket" geometry={nodes.Rocket.geometry} material={materials.Primary} position={[-0.25, 3.98, -1.38]} scale={[3.99, 7.95, 3.99]} {...extras}/>
          <mesh name="Unicorn" geometry={nodes.Unicorn.geometry} material={materials.Primary} position={[-0.25, -16.15, -1.38]} rotation={[Math.PI / 2, 0, 0]} scale={3.99} {...extras}/>
        </group>
        <group name="Camera" position={[-10.72, -5.98, 2.3]} rotation={[1.36, 0.56, 1.95]}>
            <PerspectiveCamera name="Camera_Orientation_Orientation" makeDefault far={100} near={0.1} fov={22.9} rotation={[-Math.PI / 2, 0, 0]}>
              <directionalLight
                castShadow
                position={[10, 20, 15]}
                shadow-camera-right={8}
                shadow-camera-top={8}
                shadow-camera-left={-8}
                shadow-camera-bottom={-8}
                shadow-mapSize-width={1024}
                shadow-mapSize-height={1024}
                intensity={0.2}
                shadow-bias={-0.0001}
              />
            </PerspectiveCamera>
        </group>
    </group>
  )
}

useGLTF.preload('/Master2.glb')
