import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { device } from '../utils/devices'
const items = [
  {
    title: 'Technophiles',
    description: 'We\'re passionate about tech, from large scale serverless architectures to self flying micro drones we love it all. We believe that our passion filters through to our projects which makes the world of difference.'
  },
  {
    title: 'Data Engineers',
    description: 'Have large datasets that you need help understanding? Need some anomaly detection? Want help with your self driving car system? We\'ve got you covered! Machine Learning, AI and Analytics are our staples!'
  },
  {
    title: 'Project Managers',
    description: 'With decades of experience in project management under our belts, we\'re you\'re go to "Get Stuff Done" peeps. Whether your project is greenfield or well down its path we can assist at any stage to get your project shipped.'
  },
  {
    title: 'Web3 Specialists',
    description: 'Web3 is the future, and now is the opportune time to kick off your project, be it the latest greatest NFT or a decentralized app, we\'ve got you covered!'
  },
  {
    title: 'Software Architects',
    description: 'Designing a skateboard or a car, we have truckloads of experience when it comes to designing the correct system for you. Whether you require billions of requests or a simple integration we have dealt with projects of all sizes.'
  },
  {
    title: 'Interaction Designers',
    description: 'Having been directly involved in the launch of multiple games, apps and sites, we love users and love to wow them with something new. From a 3d site that performs like a dream on any device, to tools that run with no connectivity. One of our core focuses is user interaction.'
  },
  {
    title: 'Sisu Apps',
    description: 'Dreams Visualized'
  }
]

const OverlayItem = styled.div`
  pointer-events: none;
  position: sticky;
  top: 30%;
  display: inline-block;
  padding: 0;
  padding: 80px;
  color: #FFF;
  line-height: 1.6em;
  font-size: 15px;
  letter-spacing: 1.5px;
  width: 100%;
  text-align: center;
  @media ${device.mobile} {
    padding: 80px 0px;
  }
`

const Title = styled.div`
  -webkit-font-smoothing: auto;
  pointer-events: none;
  color: #FFF;
  font-size: 5em;
  line-height: 1em;
  margin: 0;
  margin-bottom: 0.25em;
  font-family: monospace;
  @media ${device.mobile} {
    font-size: 2rem;
  }
`

const Caption = styled.span`
  pointer-events: none;
  position: fixed;
  bottom: 20px;
  right: 0;
  margin: 20px;
  color: #FFF;
  font-size: 1.4em;
  font-weight: 100;
  line-height: 1em;
  font-variant-numeric: tabular-nums;
  -webkit-font-smoothing: auto;
  font-family: monospace;
`

const Description = styled.p`
  max-width: 800px;
  text-align: center;
  margin: auto;
  font-weight: 100;
  color: #FFF;
  @media ${device.mobile} {
    max-width: 80%;
  }
`

const Overlay = forwardRef(({ caption, scroll }, ref) => {
  return (
  <div
    ref={ref}
    onScroll={(e) => {
      scroll.current = e.target.scrollTop / (e.target.scrollHeight - window.innerHeight)
      caption.current.innerText = (scroll.current.toFixed(2) * 100).toFixed(0) + '%'
    }}
    class='scroll'>
      {items.map((item, i) => {
        return (
          <div style={{ height: i === 0 ? '130vh' : '100vh' }} key={i}>
            <OverlayItem class='overlay-item'>
              <Title>"{item.title}"</Title>
              <Description>{item.description}</Description>
            </OverlayItem>
          </div>
        )
      })}
    <Caption class='caption' ref={caption}>
      0%
    </Caption>
  </div>
  )
})

export default Overlay
