import React from 'react'
import styled from 'styled-components'
import { device } from '../utils/devices'

const FooterContainer = styled.div`
  position: absolute;
  bottom: 0;
  height: 28px;
  background: #121212;
  left: 0;
  right: 0;
  color: #FFF;
  padding: 5px 10px;
  display: flex;
  font-weight: 400;
  font-size: 14px;
  @media ${device.mobile} {
    display: block;
    text-align: center;
    height: auto;
  }

`
const Divider = styled.div`
  margin-left: 10px;
  margin-right: 10px;
`

const Footer = () => {
  return (
    <FooterContainer>
      <div>Sisu Apps, LLC</div>
      <Divider>///////////////////////////</Divider>
      <div>8 The Green, Suite 10994, Dover, DE 19901</div>
      <Divider>///////////////////////////</Divider>
    </FooterContainer>
  )
}

export default Footer
